import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import $ from "jquery";
import {publicIpv4} from 'public-ip';

import SignupSuccess from './components/medico/professional/SignupSuccess'
import SignupProfessional from "./components/medico/professional/SignupProfessional";
import SignupLanding from "./components/medico/signupLanding/SignupLanding";
import Unsubscribe from "./components/medico/unsubscribe/Unsubscribe";
import RemoteConsent from "./components/medico/remoteconsent/Remoteconsent";
// @ts-ignore
import PageNotFound from "./components/medico/pageNotFound/PageNotFound";
import ForgotPassword from "./components/medico/forgotPassword/ForgotPassword";
import EventPlatformConsents from "./components/medico/eventPlatformConsents/EventPlatformConsents";
// @ts-ignore
import Maintenance from "./components/medico/maintenance/Maintenance";
import Intl from "./components/medico/intl/Intl";
import { parseLanguage } from "./translations/i18n";
import ChangePassword from "./components/medico/changePassword/ChangePassword";
import DoubleOptin from "./components/medico/doubleOptin/DoubleOptin";
import ConfigLang from "./translations/configLangContext";

import LocalizationService from "./translations/configLangContext";

const PIWIK_SCRIPTS = {
    /**
    Contains Piwik's script. If no environment found, nothing will be inserted
    Structure:
    - ENVIRONMENT: must be same name of ENVIRONMENT obj
     - - SCRIPT: must contain script body only, the "<script type="text/javascript">" tag will be automatically added
     - - OTHER: contains every obj different from a script
     */
    PRODUCTION: {
        SCRIPT: "(function(window, document, dataLayerName, id) { window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:\"stg.start\"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script'); function stgCreateCookie(a,b,c){var d=\"\";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d=\"; expires=\"+e.toUTCString()}document.cookie=a+\"=\"+b+d+\"; path=/\"} var isStgDebug=(window.location.href.match(\"stg_debug\")||document.cookie.match(\"stg_debug\"))&&!window.location.href.match(\"stg_disable_debug\");stgCreateCookie(\"stg_debug\",isStgDebug?1:\"\",isStgDebug?14:-1); var qP=[];dataLayerName!==\"dataLayer\"&&qP.push(\"data_layer_name=\"+dataLayerName),isStgDebug&&qP.push(\"stg_debug\");var qPString=qP.length>0?(\"?\"+qP.join(\"&\")):\"\"; tags.async=!0,tags.src=\"//angelini.containers.piwik.pro/\"+id+\".js\"+qPString,scripts.parentNode.insertBefore(tags,scripts); !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);\"string\"==typeof a[0]&&window[dataLayerName].push({event:n+\".\"+i+\":\"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,\"ppms\",[\"tm\",\"cm\"]); })(window, document, 'dataLayer', '9f4f8b74-f4d5-46d3-9514-aade07588494');",
        OTHER: "<noscript><iframe src=\"//angelini.containers.piwik.pro/9f4f8b74-f4d5-46d3-9514-aade07588494/noscript.html\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe></noscript>"
    },
    QA: {
        SCRIPT: "(function(window, document, dataLayerName, id) { window[dataLayerName]=window[dataLayerName]||[],window[dataLayerName].push({start:(new Date).getTime(),event:\"stg.start\"});var scripts=document.getElementsByTagName('script')[0],tags=document.createElement('script'); function stgCreateCookie(a,b,c){var d=\"\";if(c){var e=new Date;e.setTime(e.getTime()+24*c*60*60*1e3),d=\"; expires=\"+e.toUTCString()}document.cookie=a+\"=\"+b+d+\"; path=/\"} var isStgDebug=(window.location.href.match(\"stg_debug\")||document.cookie.match(\"stg_debug\"))&&!window.location.href.match(\"stg_disable_debug\");stgCreateCookie(\"stg_debug\",isStgDebug?1:\"\",isStgDebug?14:-1); var qP=[];dataLayerName!==\"dataLayer\"&&qP.push(\"data_layer_name=\"+dataLayerName),isStgDebug&&qP.push(\"stg_debug\");var qPString=qP.length>0?(\"?\"+qP.join(\"&\")):\"\"; tags.async=!0,tags.src=\"//angelini.containers.piwik.pro/\"+id+\".js\"+qPString,scripts.parentNode.insertBefore(tags,scripts); !function(a,n,i){a[n]=a[n]||{};for(var c=0;c<i.length;c++)!function(i){a[n][i]=a[n][i]||{},a[n][i].api=a[n][i].api||function(){var a=[].slice.call(arguments,0);\"string\"==typeof a[0]&&window[dataLayerName].push({event:n+\".\"+i+\":\"+a[0],parameters:[].slice.call(arguments,1)})}}(i[c])}(window,\"ppms\",[\"tm\",\"cm\"]); })(window, document, 'dataLayer', '2c491046-1080-4c53-8e58-e8f3595098e3');",
        OTHER: "<noscript><iframe src=\"//angelini.containers.piwik.pro/2c491046-1080-4c53-8e58-e8f3595098e3/noscript.html\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe></noscript>"
    }
}

const INTL_FOOTER = "Angelini S.p.a.<br>Viale Amelia 70, 00181 Rome<br>VAT NUMBER 01258691003 and Fiscal Code Company registration number: 03907010585<br>Commercial register office: Rome<br>Capital: &#x20AC; 18.594.000,00 Fully paid-up<br><br><a href=\"https://www.angelinipharma.com/gdpr/en/index.html\" target=\"_blank\">Privacy Policy</a><br><br>&#xA9; Angelini S.p.a. - All rights reserved<br>";

export const ERROR_CODES = {
    FORGOT_PASSWORD: {
        NO_EMAIL_FOUND: "FP100",
        USER_BB_NOT_REGISTERED: "FP200",
        USER_VR: "FP600",
        USER_NOT_ACTIVE: "FP700"
    },
    REGISTRATION: {
        CRM_USER_DUPLICATE: "RF100",
        EMAIL_DUPLICATE: "RF101",
        NATIONAL_ID_DUPLICATE: "RF102",
        GENERIC_ERROR: "RF103",
        DUPLICATED_EID: "RF104",
        ONEKEY_SEARCH_FAIL: "RF105",
        ONEKEY_VR_FAIL: "RF106",
        SPEC_AND_PROV_FAIL: "RF107",
        NO_INTERNET_CONNECTION: "RF108",
        BACKEND_CRASH: "RF109",
        EID_BASED_SIGNUP_MULTIPLE_FOUND: "RF111",
        CRM_EMAIL_DUPLICATED_NOT_HCP: "RF114"
    },
    CHANGE_PASSWORD: {
        CP_NO_USER_FOUND: "CP100",
        CP_EXPIRED_TOKEN: "CP200",
        CP_VALIDATION_ERROR: "CP300",
        CP_CHANGE_RETRY: "CP400",
        CP_NO_LANDING_PATH: "CP500"
    },
    SUCCESS_PAGE: {
        GENERIC_SUCCESS_PAGE_ERROR: "SP100",
        NO_AID_CODE_PROVIDED: "SP200",
        NO_USER_BY_AID_CODE: "SP300"
    },
    DOUBLE_OPT_IN: {
        REQUEST_ID_MISSING: "DO100",
        CONFIRM_FLAG_MISSING: "DO101",
        EXPIRED_BY_TIME: "DO205",
        GENERIC_EXPIRE: "DO206"
    }
}

export const DEFAUL_ERROR_MESSAGE = {
    NO_INTERNET_CONNECTION: "No internet connection, try again",
    GENERIC_ERROR: "Generic Error"
}

export const PROFESSIONAL_TYPES = {
    DOCTOR: "M",
    PHARMACIST: "P",
    USER: "U"
}

export const LANGUAGE_URL_PARAM = ":lan?"

const DB_SELECT_LINK = {
    PRODUCTION: "https://dbselect-2sj6x43swa-ew.a.run.app",
    PREPROD: "https://dbselect-preprod-2sj6x43swa-ew.a.run.app",
    QA: "https://dbselect-qa-fiizfp7riq-ew.a.run.app",
    DEV: "https://dbselect-dev-fiizfp7riq-ew.a.run.app",
    DEV_DOI: "https://dbselect-dev-doi-fiizfp7riq-ew.a.run.app"
}

const DAL_MANAGER_LINK = {
    PRODUCTION: "https://dalmanager-2sj6x43swa-ew.a.run.app",
    PREPROD: "https://dalmanager-preprod-2sj6x43swa-ew.a.run.app",
    QA: "https://dalmanager-qa-fiizfp7riq-ew.a.run.app",
    DEV: "https://dalmanager-dev-fiizfp7riq-ew.a.run.app"
}

const AID_API_LINK = {
    PRODUCTION: "https://aidapi-2sj6x43swa-ew.a.run.app",
    PREPROD: "https://aidapi-preprod-2sj6x43swa-ew.a.run.app",
    QA: "https://aidapi-qa-fiizfp7riq-ew.a.run.app",
    DEV: "https://aidapi-dev-fiizfp7riq-ew.a.run.app",
    DEV_DOI: "https://aidapi-dev-doi-fiizfp7riq-ew.a.run.app"
}

// const QA_ENVIRONMENT_URL_PREFIX = "https://qa-reg-angid";

export const OBJECTS_PATH = {
    PICTURES: {
        LOADING_GIF: "https://storage.googleapis.com/ang-custlogin-prod.appspot.com/pictures/generic_loading_gif.gif",
        GENERIC_ERROR_ICON: "https://iconsplace.com/wp-content/uploads/_icons/0000ff/256/png/error-icon-2-256.png",
        MAINTENANCE_ICON: "https://storage.googleapis.com/ang-custlogin-prod.appspot.com/pictures/maintenance-icon.png",
        SUCCESS_PAGE_COMPLETE: "https://storage.googleapis.com/ang-custlogin-prod.appspot.com/pictures/icon_registration_complete.svg",
        SUCCESS_PAGE_END: "https://storage.googleapis.com/ang-custlogin-prod.appspot.com/pictures/icon_registration_end.svg"
    }
}

const ENVIRONMENT = {
    // prefix of the env URLs
    QA: ["https://qa-dot-ang", "https://qa-reg-angid", "http://localhost"],
    DEV: ["https://dev-dot-ang", "https://dev-reg-angid", "http://127.0.0.1"],
    DEV_DOI: ["https://dev-doi-dot-ang", "https://devdoi-reg-angid"],
    PREPROD: ["https://preprod-dot-ang", "https://preprod-reg-angid"],
    PRODUCTION: []      // It is empty because it's the "fallback" choice
}

let routesArray = []

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            environment: "PRODUCTION"       // Default environment
        }
        this.culture = "";
    }

    environmentCheck(baseUrl, setStateRequired) {
        // "setStateRequired" to avoid React loop error
        for (let envKey of Object.keys(ENVIRONMENT)) {
            for(let url in ENVIRONMENT[envKey]) {
                if(baseUrl.startsWith(ENVIRONMENT[envKey][url])) {
                    if(setStateRequired) this.setState({ environment: envKey } )
                    return envKey;
                }
            }
        }
        return "PRODUCTION";
    }

    domainInfoFetcher(userIp) {
        const currentEnv = this.environmentCheck(window.location.origin, false);
        fetch(DB_SELECT_LINK[currentEnv], {
            credentials: 'same-origin',
            method: 'POST',
            body: JSON.stringify({ reqType: "cl_router", domain_url: window.location.origin, userIp: userIp }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        }).then(response => {
            if (!response.ok) { throw Error(response.statusText) }
            return response.json();
        }).then(resInfo => {
            if(resInfo.hasOwnProperty("data")) {
                this.routesBuilder(resInfo.data, resInfo.isWhiteListed, resInfo.prefCenterUrlDetail, resInfo.subLanguagesInfo);
                return resInfo.data;
            }
            if(resInfo.hasOwnProperty("isMaintaining") && resInfo.isMaintaining) {
                this.maintenanceRoutes(resInfo);
                return resInfo.isMaintaining;
            }
            else {
                console.log("ERROR: no domain url fetched");
                return false;
            }
        });
    }

    maintenanceRoutes(domainInfo) {
        routesArray.push(<Route path='/*' exact render={() => <Maintenance maintenanceSubText={domainInfo.maintenanceSubText}/>}/>);
        this.forceUpdate();
    }

    async routesBuilder(domainsRes, isWhiteListed, prefCenterUrlDetail, subLanguagesInfo) {
        let currentEnv = this.environmentCheck(window.location.origin, false);
        if(isWhiteListed) {
            // website is under maintenance and this IP is whitelisted, show VPN label
            document.getElementById("vpn_ident").style.display = "";
        }
        if(domainsRes[0].hasOwnProperty("switchToIntlLan") && domainsRes[0].switchToIntlLan) {
            // if the URL is set as 'switchToIntlLan', we're going to use International Footer
            document.getElementById('footerText').style.display = "none";       // hide 'standard' footer
            document.getElementById('footerTextIntl').innerHTML = INTL_FOOTER;  // set footer text
            document.getElementById('footerTextIntl').style.display = "";       // show international footer
        }

        var referrer = sessionStorage.getItem('aidReferrer') ? sessionStorage.getItem('aidReferrer') : document.referrer;
        if(referrer && !referrer.startsWith(domainsRes[0].domain_url)) {
            // checking if referrer base-url starts with the URL fetched from Domain_Url_CL to auto-exclude them and avoid loops
            sessionStorage.setItem("aidReferrer", referrer);        // if user change sub-language there'll be a redirect, by sessionStorage we'll not lose the referrer
        } else {
            referrer = "";
        }

        // the culture is always the same for each path
        this.culture = domainsRes[0].culture;
        let subLanForced = new URL(window.location.href).searchParams.get('sublan');
        let parsedLanguage;
        if (subLanForced) {
            // priority to 'subLanguage' param
            parsedLanguage = parseLanguage(subLanForced);
        } else if (this.culture) {
            parsedLanguage = parseLanguage(this.culture);
        }
        //else this.parsedLanguage = parseLanguage(this.props.match.params.lan);   // no forcedLanguage, read language from URL
        let domainsResFiltered = domainsRes.filter(x => x.approval_code != null);
        this.updateApprovalCode(domainsResFiltered.length > 0 ? domainsResFiltered[0].approval_code : null);

        //update body class to select the font to use
        this.updateFontBodyClass(parsedLanguage.CULTURE_CODE);

        for (let domainInfoKey of Object.keys(domainsRes)) {
            let domainInfoObj = domainsRes[domainInfoKey];

            let elemToRender;
            switch (domainInfoObj.render_page) {
                case "signup_professional": {
                    let currentEnv = this.environmentCheck(window.location.origin, false);
                    elemToRender = <Route key = {domainInfoKey} exact path = {domainInfoObj.path_url} render={(props) =>
                        <SignupProfessional {...props} profType={domainInfoObj.prof_type}
                                            forcedLanguage={domainInfoObj.culture}
                                            serviceUrl={domainInfoObj.service_url}
                                            source={domainInfoObj.source}
                                            national_id_typeCode = {domainInfoObj.national_id_typeCode}
                                            codBase = {domainInfoObj.codBase}
                                            successLanding={domainInfoObj.success_landing_path}
                                            nationalid_required = {domainInfoObj.nationalid_required}
                                            nationalid_show = {domainInfoObj.nationalid_show}
                                            nationalid_edit_required = {domainInfoObj.nationalid_edit_required}
                                            nationalid_edit_show = {domainInfoObj.nationalid_edit_show}
                                            dbSelectService = {DB_SELECT_LINK[currentEnv]}
                                            aidApiService = {AID_API_LINK[currentEnv]}
                                            intl_flow_service_url = {domainInfoObj.intl_flow_service_url}
                                            domainInfo = {domainInfoObj}
                                            referrer = {referrer}
                                            subLanguagesInfo = {subLanguagesInfo}
                                            changepass_tmp_code_show_req={domainInfoObj.changepass_tmp_code_show_req}
                                            changepass_flow = {domainInfoObj.changepass_flow}
                                            changepass_path = {domainInfoObj.changepass_path}
                                            dalService = {DAL_MANAGER_LINK[currentEnv]}
                                            />} />
                    break;
                }
                case "signup_landing": {
                    elemToRender = <Route key = {domainInfoKey} path={domainInfoObj.path_url} render={(props) => <SignupLanding {...props}
                                       doctorPath={domainInfoObj.landing_doctor_path}
                                       forcedLanguage={domainInfoObj.culture}
                                       pharmacistPath={domainInfoObj.landing_pharmacist_path}
                                       nursesPath={domainInfoObj.landing_nurses_path}
                                       userPath={domainInfoObj.landing_user_path}
                                       subLanguagesInfo = {subLanguagesInfo}
                                       dalService = {DAL_MANAGER_LINK[currentEnv]}
                                       />} />
                    break;
                }
                case "signup_success": {
                    elemToRender = <Route exact key = {domainInfoKey}  path={domainInfoObj.path_url} render={(props) => <SignupSuccess {...props}
                                        dbSelectService={DB_SELECT_LINK[currentEnv]} forcedLanguage={domainInfoObj.culture} prefCenterUrlDetail={prefCenterUrlDetail} idDomainUrl={domainInfoObj.id_domain_url} dalService = {DAL_MANAGER_LINK[currentEnv]}/>} />
                    break;
                }
                case "unsubscribe": {
                    elemToRender = <Route key = {domainInfoKey} path={domainInfoObj.path_url} render={(props) => <Unsubscribe {...props}
                                        forcedLanguage={domainInfoObj.culture}
                                        serviceUrl={domainInfoObj.service_url}
                                        subLanguagesInfo = {subLanguagesInfo}
                                        dalService = {DAL_MANAGER_LINK[currentEnv]} />} />
                    break;
                }
                case "remote_consent": {
                    elemToRender = <Route key = {domainInfoKey} path={domainInfoObj.path_url} render={(props) => <RemoteConsent {...props}
                                        forcedLanguage={domainInfoObj.culture}
                                        serviceUrl={domainInfoObj.service_url}
                                        subLanguagesInfo = {subLanguagesInfo}
                                        dalService = {DAL_MANAGER_LINK[currentEnv]} />} />
                    break;
                }
                case "forgot_password": {
                    elemToRender = <Route key = {domainInfoKey} path={domainInfoObj.path_url} render={(props) =>
                        <ForgotPassword {...props} forcedLanguage={domainInfoObj.culture} serviceUrl={domainInfoObj.service_url}
                                        redirectUrl={domainInfoObj.redirect_forgot_pass}
                                        dbSelectService = {DB_SELECT_LINK[currentEnv]}
                                        subLanguagesInfo = {subLanguagesInfo}
                                        dalService = {DAL_MANAGER_LINK[currentEnv]} />} />
                    break;
                }
                case "intl": {
                    elemToRender = <Route key = {domainInfoKey} path={domainInfoObj.path_url} render={(props) =>
                        <Intl {...props} serviceUrl={domainInfoObj.service_url} dbSelectService = {DB_SELECT_LINK[currentEnv]}
                              path_url={domainInfoObj.path_url} intlService={domainInfoObj.intl_flow_service_url} cas_login_service={domainInfoObj.cas_login_service}
                              pref_center_service_url={domainInfoObj.pref_center_service_url}
                              dalService = {DAL_MANAGER_LINK[currentEnv]} />}
                               />
                    break;
                }
                case "change_password": {
                    elemToRender = <Route key = {domainInfoKey}  path={domainInfoObj.path_url} render={(props) =>
                        <ChangePassword {...props} forcedLanguage={domainInfoObj.culture} serviceUrl={domainInfoObj.service_url}
                                        nationalid_required = {domainInfoObj.nationalid_required} changepass_tmp_code_show_req={domainInfoObj.changepass_tmp_code_show_req}
                                        success_landing_path={domainInfoObj.success_landing_path}
                                        is_doi_active={domainInfoObj.is_doi_active}
                                        prefCenterUrlDetail={prefCenterUrlDetail}
                                        dalService = {DAL_MANAGER_LINK[currentEnv]} />} />
                    break;
                }
                case "double_optin": {
                    elemToRender = <Route key = {domainInfoKey}  path={domainInfoObj.path_url} render={(props) =>
                        <DoubleOptin {...props} forcedLanguage={domainInfoObj.culture} serviceUrl={domainInfoObj.service_url}
                                        domainUrl={domainInfoObj.domain_url}
                                        success_landing_path={domainInfoObj.success_landing_path}
                                        dalService = {DAL_MANAGER_LINK[currentEnv]} />} />
                    break;
                }
                case "event_platform_consents": {
                    elemToRender = <Route key = {domainInfoKey} path={domainInfoObj.path_url} render={(props) => <EventPlatformConsents {...props}
                                        forcedLanguage={domainInfoObj.culture}
                                        serviceUrl={domainInfoObj.service_url}
                                        subLanguagesInfo = {subLanguagesInfo}
                                        domainInfo = {domainInfoObj}
                                        dalService = {DAL_MANAGER_LINK[currentEnv]} />} />
                    break;
                }
                default: {
                    break;
                }
            }
            if(elemToRender) routesArray.push(elemToRender);
        }
        let root;
        const urlParams = new URLSearchParams(window.location.search);
        if(domainsRes[0].hasOwnProperty("default_landing_path") && domainsRes[0].default_landing_path && domainsRes[0].default_landing_path.length > 1) {
            let rootRedirectUrl = domainsRes[0].default_landing_path + "?" + urlParams;
            root = <Redirect key="redirectdefault" path="/" to={rootRedirectUrl}/>
        } else {
            console.error("No default root path found on DB");
            root = <Redirect key="nodefault" path="/" to="/"/>;
        }
        routesArray.push(root);
        this.forceUpdate();
    }

    piwikScriptManager(environment) {
        if (!PIWIK_SCRIPTS[environment]) {
            // no script found for that environment
            console.log("WARNING: no script found for environment:", environment);
            return;
        }
        if(PIWIK_SCRIPTS[environment].SCRIPT) {
            // if exists, build and insert script
            let scriptElem = document.createElement("script");
            scriptElem.type = "text/javascript";
            scriptElem.innerHTML = PIWIK_SCRIPTS[environment].SCRIPT;
            document.body.appendChild(scriptElem);
        }
        if(PIWIK_SCRIPTS[environment].OTHER) {
            // if exists, build and insert !script objects
            let htmlElem = $($.parseHTML(PIWIK_SCRIPTS[this.state.environment].OTHER));
            document.body.appendChild(htmlElem[0]);
        }
    }


    async componentDidMount() {
        let environment = this.environmentCheck(window.location.origin, true);
        this.piwikScriptManager(environment);

        try {
            let publicIp = await publicIpv4();
            this.domainInfoFetcher(publicIp);
        } catch (e) {
            // if something went wrong, call domainInfoFetcher anyway
            this.domainInfoFetcher();
        }
    }

    updateApprovalCode(approvalCode) {

        if (approvalCode != null) {
            document.querySelector("#approval-code").style.display = "block";
            document.querySelector("#approval-code p").innerHTML = approvalCode;
        }
        else {
            document.querySelector("#approval-code").style.display = "none";
        }
    }

    updateFontBodyClass(culture) {
        //document.documentElement.setAttribute("lang", culture);
        //document.documentElement.classList.remove(culture);
        document.body.classList.add('site-' + culture);
    }
    defaultRootRedirect() {
        // to avoid 404 error in QA environment, we must check before componentDidMount if root path was required by QA or PROD address
        const currentEnv = this.environmentCheck(window.location.origin, false);
        switch (currentEnv) {
            case "DEV": return <Redirect to={"/signup-dev"}/>;
            case "QA": return <Redirect to={"/signup-qa"}/>;
            default: return <Redirect to={"/signup"}/>;
        }
    }

    render() {
        if (routesArray.length === 0) {
            return (
                <div className="o-headers">
                    <div className="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                    </div>
                </div>
            );
        } else {
            return (
                <Switch>
                    {routesArray}
                    <Route path='/' exact render={() => this.defaultRootRedirect()} />
                    <Route path="" render={(props) => <PageNotFound />} />
                </Switch>
            )
        }
    }
}

export { App };
