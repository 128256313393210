import React from "react";
import {parseLanguage, DEFAULT_LANGUAGE} from "../../../translations/i18n";
import {Redirect} from "react-router-dom";
import {Helmet} from "react-helmet";
import {ERROR_CODES, OBJECTS_PATH} from '../../../App.js';
import LocalizationService from "../../../translations/configLangContext";
import {i19n} from "../../../translations/i19n";
import {SUPPORTED_LANG} from "../../../translations/i18n";

const RENDER_STATUS = {
    LOADING: "LOADING",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS"
}

const PLUS_ENABLE_KEY = [
    "doubleOptin.doi_web_reg_confirmed_pharmacist",
    "doubleOptin.doi_web_upgrade_confirmed_pharmacist",
    "doubleOptin.doi_crm_reg_confirmed_pharmacist",
    "doubleOptin.doi_prefcenter_setconsent_confirmed_pharmacist"
]
export default class DoubleOptin extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.subLanForced = null;
        this.errorText = "";
        this.errorType = "";
        this.userMessage = "";
        this.showReturnButton = false;
        this.professionalType = "";

        this.urlToRedirect = null;      // it could be both target_url or referrer values from DB
        this.state = {
            timer: 5,  //set countdown timer before redirect
            prefCenterUrl: null
        }
        this.countdown = 0;
        this.doiType = null;
        this.translateCompleted = false;
        this.enablePlusButton = false;
    }

    async componentDidMount() {
        this.step = RENDER_STATUS.LOADING;
        this.doiRequest();

        const langContext = LocalizationService.getInstance();
        await langContext.saveAndSynch(this.props.dalService, this.professionalType, this.parsedLanguage.CULTURE_CODE, "double_optin");
        this.setState({ translateCompleted: true});

    }

    footerLoc(){
        let localized = i19n.t('footer');
        let localizedP = this.professionalType !== "" ? i19n.t('footer_'+this.professionalType) : false;
        if(localizedP && i19n.t('footer_'+this.professionalType) !== "") {
            document.getElementById('footerText').innerHTML = localizedP;
        } else if (localized && i19n.t('footer') !== "") {
            document.getElementById('footerText').innerHTML = localized;
        }
    }

    doiRequest() {
        let currUrl = new URL(window.location.href);
        let params = new URLSearchParams(currUrl.search);
        let reqId = params.get("reqid");
        let confirmFlag = params.get("confirm");

        if(!reqId) {
            this.step = RENDER_STATUS.ERROR;
            this.errorText = i19n.t("doubleOptin.errors.missingParam");
            this.errorType = ERROR_CODES.DOUBLE_OPT_IN.REQUEST_ID_MISSING;
            this.forceUpdate();
            return;
        }
        if(!confirmFlag) {
            this.step = RENDER_STATUS.ERROR;
            this.errorText = i19n.t("doubleOptin.errors.missingParam");
            this.errorType = ERROR_CODES.DOUBLE_OPT_IN.CONFIRM_FLAG_MISSING;
            this.forceUpdate();
            return;
        }

        let values = {
            reqId: reqId,
            confirmFlag: confirmFlag,
            reqType: "updateDoiRequest"
        }
        fetch(this.props.serviceUrl, {
            credentials: 'same-origin',
            method: 'POST',
            body: JSON.stringify(values),
            headers: new Headers({'Content-Type': 'application/json'})
        })
        .then(response => { return response.json(); })
        .then(async res => {
            this.step = RENDER_STATUS.SUCCESS;
            if (res.frontendKey) {
                this.userMessage = res.frontendKey;

                if (PLUS_ENABLE_KEY.includes(res.frontendKey) && this.parsedLanguage.CULTURE_CODE === SUPPORTED_LANG.ITALIAN.CULTURE_CODE) {
                    this.enablePlusButton = true;
                }
            }
            if (res.showReturnButton) this.showReturnButton = res.showReturnButton;
            if (res.errorCode) this.errorType = res.errorCode;
            if (res.errorMessage) console.error(res.errorMessage);
            if (res.professionalType) this.professionalType = res.professionalType;
            if (res.doiType) this.doiType = res.doiType;
            if (res.doiType && res.doiType === "AID_REGISTRATION" && confirmFlag == "true") {
                if (res.referrer) this.urlToRedirect = res.referrer;
                if (res.targetUrl && res.targetUrl !== "login") this.urlToRedirect = res.targetUrl;
                this.loadCounter();
            }
            if(res.culture) {
                parseLanguage(res.culture);
            }

            const langContext = LocalizationService.getInstance();
            await langContext.saveAndSynch(this.props.dalService, this.professionalType, res.culture ?? this.parsedLanguage.CULTURE_CODE, "double_optin");

            this.forceUpdate();
        }).catch(async error => {
            console.log("Error", error);
        });
    }

    loadCounter() {
        if (this.urlToRedirect && this.urlToRedirect !== "" && this.countdown === 0) {
            this.countdown = setInterval(()=>{this.setState({timer: this.state.timer -1})}, 1000);
        }
    }

    componentDidUpdate(){
        //stop countdown when timer reaches 0, then redirect to target
        if(this.state.timer === 0 && !(this.urlToRedirect.includes("plus.angelinipharma.it") && this.doiType === "AID_REGISTRATION")) {
            clearInterval(this.countdown);
            if (window.opener && window.opener !== window) {
                window.opener.postMessage("loadSuccess", "*");
            } else {
                window.location.replace(this.urlToRedirect);
            }
        }
    }

    subLanguageDefaultMan() {
        let defaultSubLan;
        for(let subLanInfo of this.props.subLanguagesInfo) {
            if(subLanInfo.isDefaultSubLan) defaultSubLan = subLanInfo
        }
        if(this.parsedLanguage && defaultSubLan.sub_culture && this.parsedLanguage.CULTURE_CODE !== defaultSubLan.sub_culture) this.subLanguageEventHandler(defaultSubLan.sub_culture)
    }

    subLanguageEventHandler(subLanKey) {
        // to avoid all reload-related issues we use 'sublan' param workaround
        let redirectUrl = new URL(window.location)
        redirectUrl.searchParams.set('sublan', subLanKey);
        window.location = redirectUrl;
    }

    render() {
        this.subLanForced = new URL(window.location.href).searchParams.get('sublan');
        if(this.subLanForced) {
            // priority to 'subLanguage' param
            this.parsedLanguage = parseLanguage(this.subLanForced);
        }
        else if(this.props.forcedLanguage) {
            // if 'forcedLanguage' means that this domain must be shown to selected language only
            this.parsedLanguage = parseLanguage(this.props.forcedLanguage);
        }
        else this.parsedLanguage = parseLanguage(this.props.match.params.lan);   // no forcedLanguage, read language from URL
        if(!this.parsedLanguage) {
            // language not recognized, fallback and redirect to default one
            let localizedPath = this.props.match.path.replace(":lan?", DEFAULT_LANGUAGE.LAN_CODE);
            return <Redirect to={localizedPath} />
        }

        if(this.props.subLanguagesInfo && !this.subLanForced) this.subLanguageDefaultMan();

        if(!this.state.translateCompleted) {
            return (
                <div className="o-headers">
                    <div className="h-img">
                        <img id="img" src={OBJECTS_PATH.PICTURES.LOADING_GIF} alt='Loading...' width="130px" height="130px" />
                    </div>
                </div>
            );
        }

        this.footerLoc();

        switch (this.step) {
            case RENDER_STATUS.ERROR: {
                let errorTextToShow = this.errorText ? this.errorText : i19n.t("doubleOptin.errors.generic");
                return (
                    <div>
                        <h1 dangerouslySetInnerHTML={{__html: errorTextToShow}} style={{width: 'fit-content', display: 'block', margin: 'auto', textAlign: 'center' }}/>
                        {this.errorType.length > 0
                            ? <p style={{width: 'fit-content', display: 'block', margin: 'auto', marginTop: '10%' }}>{`${i19n.t("changePassword.errors.shortIntro")}: ${this.errorType}`}</p>
                            : ""
                        }
                    </div>
                )
            }
            case RENDER_STATUS.SUCCESS: {
                let messageTextToShow = this.userMessage ? i19n.t(this.userMessage) : "";
                return (
                    <div>
                        <h1 dangerouslySetInnerHTML={{__html: messageTextToShow}} style={{width: 'fit-content', display: 'block', margin: 'auto', textAlign: 'center' }}/>

                        {(this.urlToRedirect && !(this.urlToRedirect.includes("plus.angelinipharma.it") && this.doiType === "AID_REGISTRATION")) ?
                            <div className="o-prose">
                                <div className="e-content">
                                    <p className="f-2" dangerouslySetInnerHTML={
                                        {__html: i19n.t('professional.succesPageRedirect', {time: `${this.state.timer}`, link: `<a class="link-break" href="${this.urlToRedirect}">${this.urlToRedirect}</a>`, link_text: `${this.urlToRedirect}`})}
                                    } />
                                </div>
                            </div>
                            : ""
                        }

                        {(this.urlToRedirect && (this.urlToRedirect.includes("plus.angelinipharma.it") && this.doiType === "AID_REGISTRATION") && i19n.t("professional.successReturnPlus") !== "" ) ?
                            <fieldset className="c-fieldset">
                                {/* TODO THIS SHIT HAS BEEN MOCKED TO PASS THE UAT, WE MUST MAKE IT DYNAMIC */}
                                <div className="u-wrap-fields">
                                    <div className="form-row">
                                        <div className="form-group form-group-buttons">
                                            <div className="o-button o-button-full">
                                                <a className="c-button c-button-outline txt-uppercase" href={this.urlToRedirect}>{i19n.t("professional.successReturnPlus")}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            : ""
                        }

                        {(this.enablePlusButton) ?
                            <fieldset className="c-fieldset">
                                {/* TODO THIS SHIT HAS BEEN MOCKED TO PASS THE UAT, WE MUST MAKE IT DYNAMIC */}
                                <div className="u-wrap-fields">
                                    <div className="form-row">
                                        <div className="form-group form-group-buttons">
                                            <div className="o-button o-button-full">
                                                <a className="c-button c-button-outline txt-uppercase" href={i19n.t("professional.successReturnPlusPharmacistUrl")}>{i19n.t("professional.successReturnPlusPharmacist")}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            : ""
                        }

                        { this.userMessage && this.showReturnButton ?
                            <fieldset className="c-fieldset">
                                {/* TODO THIS SHIT HAS BEEN MOCKED TO PASS THE UAT, WE MUST MAKE IT DYNAMIC */}
                                <div className="u-wrap-fields">
                                    <div className="form-row">
                                        <div className="form-group form-group-buttons">
                                            <div className="o-button o-button-full">
                                                <a className="c-button c-button-outline txt-uppercase" href={ this.props.domainUrl }>{`${i19n.t('doubleOptin.refusedDoiButtonText')}`}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                            : ""
                        }
                    </div>
                )
            }
            case RENDER_STATUS.LOADING:
            default: {
                return (
                    <div>
                        <Helmet>
                            <title>{i19n.t('unsubscribe.pageTitle')}</title>
                            <meta name="description" content={i19n.t('unsubscribe.pageTitle')} />
                        </Helmet>
                        <img id="img" alt="Loading" src={OBJECTS_PATH.PICTURES.LOADING_GIF} style={{width: '130px', height: '130px', display: 'block', margin: 'auto' }}/>
                    </div>
                )
            }
        }
    }
};
